.common-filters {
  & > form {
    & > .ant-flex.ant-flex-align-end {
      align-items: start;

      & > div {
        padding-top: 26px;
        white-space: nowrap;

        &[data-testid='default-filters'] {
          padding-top: 0;
          white-space: unset;
        }
      }
    }
  }

  .add-menu-item {
    .anticon-filter {
      display: none;
    }
  }
}

.te-filters {
  .dropdown-content {
    max-height: 350px;
    overflow: auto;
  }
}
