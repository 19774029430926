.create-new-import-template-modal {
  &__information-fields {
    display: grid;
    grid-template-columns: 350px 24px;
    grid-gap: 12px;
    align-items: center;
  }

  &__quick-summary {
    margin-bottom: 20px;
  }

  ul {
    margin: 0;
    padding-left: 14px;
  }

  &__grid {
    display: grid;
    width: 100%;
  }

  &__grid--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .ant-table-thead {
    top: 0px;
  }
}
