.modal-create-new-pathway {
  .course-selector {
    width: 100%;
    .datasource-selector {
      .ant-select {
        width: 100%;
      }
    }
  }
}
