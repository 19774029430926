.truncated-text {
  position: relative;

  .placeholder-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }

  .ant-typography {
    margin-bottom: 0;
  }
}
