@import '~@timeedit/ui-components/lib/src/assets/styles/variables';

.highlighted-background {
  position: absolute;
  width: calc(220%);
  content: '';
  margin: auto;
  top: -3px;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--range-background);
  z-index: var(--base-z-index);
  border-top: 1px solid var(--range-border-color);
  border-bottom: 1px solid var(--range-border-color);
}

.is-edge-of-range {
  content: '';
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 38px;
  border: 1px solid var(--range-border-color);
  background-color: var(--range-background);
}

.weeks-selector-modal {
  .ant-modal-body {
    overflow: visible !important;
  }
}
.weeks-selector {
  --range-background: #{$colorPrimaryBgHover};
  --range-border-color: #{$colorPrimary};
  --base-z-index: 8;
  font-size: 16px;
  & > * {
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -8px;
    margin-right: -8px;
  }
  b {
    font-weight: 600;
  }
  &__weeks {
    display: inline-grid;
    grid-template-columns: repeat(10, 50px);
    overflow: hidden;
    gap: 12px;
    padding: 8px;
  }

  &__week {
    position: relative;
    button {
      background-color: $colorPrimaryBorderHover;
      border: 1px solid $colorPrimary;
      color: $colorText;
      padding-left: 4px !important;
      padding-right: 4px !important;
      width: 100%;
      z-index: calc((var(--base-z-index)) + 1);
      box-shadow: none;
      span {
        font-size: 14px;
        font-weight: 600;
      }
    }

    &.is-full-fill {
      button:not(.ant-btn-background-ghost) {
        background-color: $colorPrimary;
        color: #fff;
      }
    }

    &.highlighted {
      &:not(.in-range) {
        &::after {
          @extend .highlighted-background;
        }
      }
      span {
        position: relative;
      }
    }
  }

  &__tooltip {
    z-index: 9999;
  }

  .is-disabled:not(.is-hovering) {
    --range-background: $colorBg;
    --range-border-color: #c4c4c4;
    --base-z-index: 7;

    button:disabled {
      background-color: #ebebeb;
      border-color: var(--range-border-color);
    }
  }

  .is-in-range,
  .is-first-in-range,
  .is-last-in-range {
    &::after {
      @extend .highlighted-background;
    }
    &::before {
      @extend .is-edge-of-range;
    }
  }
  .is-last-in-range {
    &::after {
      left: 0;
      width: 110%;
    }
  }
  .is-first-in-range {
    &::after {
      left: 100%;
      width: 110%;
    }
  }

  .is-last-hovering {
    & + .in-range,
    & + .is-last-in-range {
      &::after {
        display: none !important;
      }
    }
  }

  .is-first-hovering.is-last-hovering.is-first-in-range.is-last-in-range {
    &::after {
      display: none !important;
    }
  }
  .is-hovering {
    &.is-first-in-range {
      &:not(.is-first-hovering) {
        &::after {
          display: block;
        }
      }
    }
  }
}
