@import '../styles/globals.scss';

.page--pathway-service {
  height: 100%;
  overflow: hidden;
  & > div {
    height: 100%;
    min-height: 100%;
  }

  .ant-tabs-tabpane {
    flex-flow: column;
    width: 100%;

    &:not(.ant-tabs-tabpane-hidden) {
      display: flex;
    }
  }

  .ant-dropdown-menu {
    position: fixed;
  }

  .tabpane__wrapper {
    padding-top: 16px;
    display: flex;
    flex-flow: column;
    height: 100%;
    min-height: 0;
    overflow: hidden;

    & > div.container {
      height: 100%;
      display: flex;
      flex-flow: column;
    }

    .ant-tabs {
      flex: 1;
      min-height: 0;
    }
  }

  .table-wrapper {
    flex: 1;
    margin-top: 24px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ant-tabs-content {
    height: 100%;
    display: flex;
  }
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 0;
  }
  .ant-typography {
    margin-bottom: 0;
  }

  .container {
    padding-left: 24px;
    padding-right: 24px;

    &--wider {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .ant-tabs-content-holder {
    flex: 1;
  }
}

.BaseTable__row-cell {
  .ant-skeleton-content {
    margin-bottom: 0;
  }
}

.status__tag--success {
  background-color: $colorSuccessBg;
  color: $colorSuccessText;
  border: 1px solid $colorSuccessBorder;
}

.status__tag--info {
  background-color: $colorInfoBg;
  color: $colorInfo;
  border: 1px solid $colorInfoBorder;
}

.status__tag--error {
  color: $colorErrorText;
  background-color: $colorErrorBg;
}

.red_icon--warning {
  color: $colorError;
}

.fullscreentable {
  .table-wrapper {
    margin: 0;
    padding: 0;
  }
  .header-extra {
    flex: 1;
    padding-left: $defaultPadding / 2;
  }
  margin-bottom: 50px;
}

.objecttable {
  .table-wrapper {
    margin: 0;
    padding: 0;
  }

  .ant-table-wrapper {
    .ant-pagination.ant-table-pagination {
      position: sticky;
      top: 0;
      z-index: 20;
      padding: 6px;
      margin: 0;
      background: #fafafa; // The color here need to be solid, since there are elements behind.
    }
  }
}

.default-layout-wrapper .default-layout-content-wrapper {
  overflow-y: auto;
}
