.activity-details {
  .ant-drawer-body {
    .ant-form-item-row {
      flex-wrap: nowrap;
    }

    .ant-form-item-label > label .ant-form-item-tooltip {
      color: inherit;
    }
  }
}
