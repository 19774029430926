.object-filter {
  display: inline-flex !important;
}
.datasource-selector {
  & > div {
    width: 100%;
  }
}

.object-filter-modal {
  .ant-modal-body {
    overflow: hidden !important;
  }

  &.object-filter-modal--no-filter {
    & > .ant-modal {
      max-width: 600px;

      .object-filter__flex-item {
        .object-filter__card {
          min-width: 100%;
        }
      }
    }
    .ant-modal-footer {
      & > div {
        & > div:first-child {
          div:nth-of-type(2) {
            display: none;
          }
        }
      }
    }
  }
}
.datasource-element {
  &__rendered-value {
    & > span {
      line-height: 24px;
      min-height: 32px;
      display: flex;
      align-items: center;
    }
  }
}
